<template>
<Card>
    <template #content>
        <p>Este es el listado preliminar de cargos a ser emitidos. Si desea eliminar alguno de los cargos, lo puede hacer marcando el cheque que aparece en el extremo izquierdo del mismo. Cuando esté listo para proceder con la emisión presione el botón “Emitir Cargos”, ubicado en la esquina inferior derecha de la página.</p>
        <Divider />      		    
        <DataTable :value="cargos" editMode="cell" dataKey="id_movimiento" v-model:selection="selectedCargos" :rowClass="rowClass" v-model:expandedRows="expandedRows" responsiveLayout="scroll" class="p-datatable-sm"
		filterDisplay="menu">
			<template #header>        
				<div class="p-d-flex p-jc-end">
					<Button label="Emitir Cargos" @click="emitir()" class="p-button-warning" icon="pi pi-cog" :disabled="totalTrxAEmitir.length==0"/>
				</div>
			</template>		
			<template #empty>
				No hay cargos 
			</template>		
             <Column :expander="true" headerStyle="width: 3rem" />   
			<Column selectionMode="multiple" headerStyle="width: 3rem"/>
            <!-- <Column field="emitir" headerStyle="width: 3em">
				<template #header>
					<i class="pi pi-cog"/>
				</template>					
				<template #body="slotProps">
					<Checkbox v-bind:id="slotProps.data.id_movimiento" v-model="slotProps.data[slotProps.column.props.field]" :binary="true" :disabled="slotProps.data.emitido"/>			
				</template>
			</Column>              -->
            <Column field="cta_habiente" header="Inmueble"/>             
            <!-- <Column field="vigencia_final" header="Vencimiento"/>                    -->
            <!-- <Column field="plantilla" header="Plan"/> -->
			<!-- <Column field="id_mov_plantilla" header="Plantilla"/> -->
			<Column field="movimiento_tipo" header="Tipo"/>			
			<Column field="referencia" header="Referencia"/>
            <Column field="plantilla" header="Plan"/>
            <Column field="doc_fecha" header="Fecha Emisión"/>                             
            <Column field="doc_emision" header="Documento"/>     
			<Column field="fecha_pago" header="Vencimiento"/>                                 
            <Column field="total" header="Total"> 
				<template #body="slotProps">					
                   	<div class="p-d-flex p-jc-end">
                        <div>{{slotProps.data.id_moneda}} {{formatCurrency(slotProps.data.total)}}</div>
                    </div>   					
				</template>
			</Column>	
            <!-- <Column field="enviar_notificacion" headerStyle="width: 3em">
				<template #header>
					<i class="pi pi-envelope"/>
				</template>			
				<template #body="slotProps">
					<Checkbox  v-bind:id="slotProps.data.enviar_notificacion" v-model="slotProps.data[slotProps.column.props.field]" :binary="true" :disabled="slotProps.data.emitido"/>			
				</template>
			</Column>    								     -->
            <template #expansion="slotProps">
                <div class="orders-subtable">
                    <DataTable :value="slotProps.data.detalle" dataKey="id_mov_det" responsiveLayout="scroll" class="p-datatable-sm"  selectionMode="single">
                        <Column field="codigo" header="Código" style="flex: 0 0 8rem"></Column>
                        <Column field="producto" header="Producto" style="flex: 0 0 2rem"></Column>
                        <Column field="cantidad" header="Cantidad" style="flex: 0 0 2rem"></Column>
						<Column field="precio_unitario" header="Precio U." style="flex: 0 0 4rem">
                          <template #body="slotProps">
                                {{formatCurrency(slotProps.data.precio_unitario)}}
                            </template>
                        </Column>
						<Column field="total" header="Total">					
                            <template #body="slotProps">
                                {{formatCurrency(slotProps.data.total)}}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template>
			<ColumnGroup type="footer">
				<Row>
					<Column footer="Documentos:" :colspan="3" footerStyle="text-align:right" />
					<Column :footer="totalTrxAEmitir.length"/>
					<Column :footer="montoTotalAOperar" :colspan="6" footerStyle="text-align:right" />								
				</Row>
			</ColumnGroup>					
        </DataTable>				
    </template>
    <template #footer>        
        <div class="p-d-flex p-jc-between">
            <Button label="Regresar"  @click="prevPage()" icon="pi pi-angle-left"/>
            <Button label="Emitir Cargos" @click="emitir()" class="p-button-warning" icon="pi pi-cog" :disabled="totalTrxAEmitir.length==0"/>
        </div>
    </template>
</Card>

<Dialog v-model:visible="calculandoDlg" :showHeader="true" :modal="true" :closable="false">			
	<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
		<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
			<img src="/assets/layout/images/calculator.gif" width="64" height="64"/>
		</div>		
		<div class="p-d-flex p-ai-center p-jc-center p-text-bold">
			<div class="text-lg">Por favor espere un minuto mientras se realizan los cálculos…</div>
		</div>
	</div>
</Dialog>

<Dialog v-model:visible="procesandoDlg" :showHeader="true" :modal="true" :closable="false">			
	<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
		<div class="p-d-flex p-ai-center p-jc-center">
			<!-- <i class="pi pi-send p-m-2" style="font-size: 2rem"></i> -->
			<img src="/assets/layout/images/document.gif" width="64" height="64"/>
		</div>
		<div class="flex align-items-center justify-content-center mt-5">				
		<ProgressBar :value="secuencia">										
			<div class="font-light">
			Emitiendo {{secuencia}} de {{selectedCargos.length}}...
			</div>
		</ProgressBar>
		</div>
	</div>
</Dialog>

</template>
<script>
import MdsService from '../../service/mds.service';
import { CURRENT_APP_MODE } from '../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'MdsEmisionRevision',
    components: {
        Divider
    },    
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id: '',
					id_mov_plantilla: '0',
					id_cta_habiente_info: '',
					id_categoria: '',
					tasa_cambio: 7.82,
					fecha_emision: new Date(),
					cambiar_fecha_pago: false,
					fecha_pago: new Date(),
					Param1: '',
					Param2: '',
					Param3: '',
					Param4: '',
					Param5: '',
					Param6: '',							
				}
			}
		}
	},          
    data() {        
        return {         
            appMode: CURRENT_APP_MODE,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			idMoneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda,   			
			id: this.formData.id,
			id_mov_plantilla: this.formData.id_mov_plantilla,
			id_cta_habiente_info: this.formData.id_cta_habiente_info,
			id_categoria: this.formData.id_categoria,
			tasa_cambio: this.formData.tasa_cambio,
			fecha_emision: this.formData.fecha_emision,
			cambiar_fecha_pago: this.formData.cambiar_fecha_pago,
			fecha_pago: this.formData.fecha_pago,
			Param1: this.formData.Param1,
			Param2: this.formData.Param2,
			Param3: this.formData.Param3,
			Param4: this.formData.Param4,
			Param5: this.formData.Param5,
			Param6: this.formData.Param6,		            
			trxs: null,
			secuencia: 1,
			selectedCargos: null,
			calculandoDlg: false,
			procesandoDlg: false,
			cargos: [],
			gran_total: 0.0,
            cargosAEmitir: null,
			expandedRows: [],
        }
    },
    async mounted()
    {
		await this.generar();
    },
	computed:{
		totalTrxAEmitir()
		{
			let movs_a_emitir = [];
			if(this.selectedCargos){

				movs_a_emitir = this.selectedCargos.filter(element => (element.emitir && !element.emitido));
			}
			return movs_a_emitir;
		},

		montoTotalAOperar()
		{
			return `Total: ${this.formatCurrency(this.gran_total)}`;
		},		
	},    
    methods: {
		generar(){

            let condiciones = {
				id: this.id,
				id_mov_plantilla: this.id_mov_plantilla,
				id_cta_habiente_info: this.id_cta_habiente_info,
				id_categoria: this.id_categoria,
				tasa_cambio: this.tasa_cambio,
				fecha_emision: this.fecha_emision,
				cambiar_fecha_pago: this.cambiar_fecha_pago,
				fecha_pago: this.fecha_pago,
				Param1: this.Param1,
				Param2: this.Param2,
				Param3: this.Param3,
				Param4: this.Param4,
				Param5: this.Param5,
				Param6: this.Param6,  
				enviar_notificacion: false              
            }

			this.calculandoDlg = true;
			this.gran_total = 0.0;

            MdsService.GetTrxXContrato(this.$store.state.auth.currentAppCtx, condiciones).then(data => {					
				this.calculandoDlg = false;				
				this.cargos = data;	

				this.gran_total = 0.0;	
				let i;
				for (i = 0; i < this.cargos.length; i++) {
					this.gran_total += this.cargos[i].total;
				}													
			}).catch(err => {
				this.calculandoDlg = false;
				this.cargos = []
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})				
				}
			);	
		},

		async emitir() {
			let i;
			let cargo;
			var index = -1;
			var data;

			this.secuencia = 1;
			this.procesandoDlg = true;
			try {
				for (i = 0; i < this.selectedCargos.length; i++) {
					cargo = this.selectedCargos[i];
					try {
						if(cargo.emitir && !cargo.emitido) {
							data = await MdsService.emitirTrxXID(this.$store.state.auth.currentAppCtx, 
							{
								id_movimiento: `${cargo.id_movimiento}`,
								id: `${cargo.id_cta_habiente_info}`,
								id_cta_habiente_info: `${cargo.id_cta_habiente_info}`,						
								fecha_emision: cargo.fecha,
								fecha_pago: cargo.fecha_pago,
								id_mov_plantilla: `${cargo.id_mov_plantilla}`,
								tasa_cambio: this.tasa_cambio,
								Param1: '',
								Param2: '',
								Param3: '',
								Param4: '',							
								Param5: '',
								Param6: '0',
								enviar_notificacion: false
							});

							index = this.findIndexMovimiento(data.id_movimiento_old);
													
							this.cargos[index].id_movimiento = data.id_movimiento;
							this.cargos[index].doc_emision = data.doc_emision;
							this.cargos[index].doc_fecha = data.doc_fecha;
							this.cargos[index].msg_error = data.msg_error;
							this.cargos[index].emitido = true;

							if(data.msg_error != '')
							console.log(`... error [${cargo.msg_error}]`);													
						}
					} catch(error) {
						cargo.msg_error = error;
						this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})
					}
					this.secuencia++;
				}	
				this.nextPage();
			} finally {
				this.procesandoDlg = false;
				this.secuencia = 0;
			}
		},		
        async nextPage() {
			var emisiones = await this.cargos.filter(cargo => cargo.emitido);

			let formData = {
				id: this.id,
				id_mov_plantilla: this.id_mov_plantilla,
				id_cta_habiente_info: this.id_cta_habiente_info,
				id_categoria: this.id_categoria,
				tasa_cambio: this.tasa_cambio,
				fecha_emision: this.fecha_emision,
				cambiar_fecha_pago: this.cambiar_fecha_pago,
				fecha_pago: this.fecha_pago,
				cargosEmitidos: emisiones,
				Param1: this.Param1,
				Param2: this.Param2,
				Param3: this.Param3,
				Param4: this.Param4,
				Param5: this.Param5,
				Param6: this.Param6
			};

            this.$emit('nextPage', {condiciones: formData, pageIndex: 3});
        },
        onRowSelect(data) {
            data.excluir = data.excluir == 0? 1: 0;						
			return (data.excluir == 1);			
        },
		rowClass(data)
		{
			var className = (data.emitir?"emitir-row":"regular-row");
			return className;
		},
		findIndexMovimiento(idMovimiento)
		{
			var index = -1;
			for(var i=0; i<this.cargos.length; i++)
			if(this.cargos[i].id_movimiento === idMovimiento) {
				index = i;
				break;
			}

			return index;
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	        
        prevPage() {
            this.$emit('prevPage', {pageIndex: 3});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}

::v-deep(.regular-row) {
	font-weight: 300;
	background: white;
}

::v-deep(.emitir-row) {
	font-weight: bold;
	background:white;
}

</style>